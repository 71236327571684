
@import '../../bower_components/material-design-lite/material.min.css';
@import '../../bower_components/material-design-icons/iconfont/material-icons.css';
@import '../../bower_components/select2.min.css';
.login_card{
    min-width: auto;
    .isd-input{
    	.select2{
    		.selection{
    			.select2-selection--single{
				    border: none;
					border-radius: 0px;
					border-bottom: 1px solid rgba(0,0,0,.12);
				    outline: none;
					#select2-tags-container{
						padding-left: 0px;
						text-align: left;
						color: inherit;
						outline: none;
					}
				}
			}
    	}
    }
    @media (max-width:360px) {
		.login_card{
	    	min-width: auto;
		}	
	}
	@media (max-width: 839px) and (min-width: 480px) {
		.mdl-cell--6-col, .mdl-cell--6-col-tablet.mdl-cell--6-col-tablet {
		    width: calc(75% - 36px);
		}
		.mdl-cell--2-col, .mdl-cell--2-col-tablet.mdl-cell--2-col-tablet {
		    width: calc(25% - 0px);
		}
	}
	@media (max-width: 479px) {
		.mdl-cell--1-col, .mdl-cell--1-col-phone.mdl-cell--1-col-phone {
		    width: calc(25% - 0px);
		}
	}
	@media (max-width: 479px) {
		.mdl-cell--3-col, .mdl-cell--3-col-phone.mdl-cell--3-col-phone {
		    width: calc(75% - 46px);
		}
	}
}
.select2-dropdown{
    box-shadow: rgba(0, 0, 0, 0.137255) 0px 2px 2px 0px, rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.117647) 0px 1px 5px 0px;
    border: 1px solid rgba(0, 0, 0, 0.117647);
}
.app-view{
	
	.subject-card,.chapter-card{
		justify-content: center;
	    align-items: center;
		.subinfo {
			@media(max-width: 650px) and (min-width: 570px){
				.mdl-card__title{
					width: 28%;
				}
			}
		}
		@media (max-width: 570px) and (min-width: 480px) {
			.mdl-cell--4-col, .mdl-cell--4-col-tablet.mdl-cell--4-col-tablet {
			    width: calc(90% - 16px);
			    .mdl-card__title{
					/*width: 28%;*/
				}
			}
		}
	}
}

